.request-new-bankbox-wrapper-index-version-one-five {
  width: 40rem;
  margin-top: 3rem;
  padding-bottom: 10rem;
  // background-color: red;
  overflow-y: scroll;
  //   height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .scrollable-div-box {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    // background-color: red;
    // height: 100%;

    .select-devive-type-box {
      width: 100%;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 3;
      border: unset;
      box-shadow: unset;

      .select-box-list-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 1.2rem;
        border-radius: 4rem;
        align-self: center;
        gap: 1rem;
        width: max-content;
        width: 100%;

        @media only screen and (max-width: 48em) {
          border-radius: 1.2rem;
          padding: 0.5rem 0.7rem;
        }

        .item-box {
          padding: 1.2rem 2.7rem;
          display: flex;
          justify-content: center;
          border-radius: 4rem;
          cursor: pointer;

          @media only screen and (max-width: 48em) {
            // border-radius: 1.2rem;
            padding: 1rem 2rem;
          }

          .item {
            font-size: 1.45rem;
          }
        }

        .item-box-active {
          cursor: not-allowed;
          .item {
            font-weight: 600;
          }
        }
      }
    }

    .auto-generate-acc-num-box {
      display: flex;
      flex-direction: column;

      .label {
        align-self: flex-start;
        margin-bottom: 0.4rem;
        font-size: 1.3rem;
        color: #020202;
        text-transform: capitalize;
      }

      .input-auto-generate-box {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
        position: relative;
        width: 100%;
        // background-color: red;

        // .form-auto-input{
        //   // position: a;
        //   width: calc(100% - 6rem);
        //   position: absolute;
        //   top: 50%;
        //   left: 0;
        //   transform: translateY(-50%);

        //   &>*{
        //     width: 100%;
        //   }
        // }

        .generate-box-wrap {
          // position: absolute;
          // top: 50%;
          // right: 0;
          // width: 6rem;
          // transform: translateY(-50%);

          .generate-box {
            width: 6rem;
            height: 5rem;
            border-radius: 0.8rem;
            display: grid;
            place-items: center;
            cursor: pointer;

            &:hover {
              .img-box {
                transform: scale(1.1);
              }
            }

            .img-box {
              width: 55%;
              height: 55%;
              transition: transform 0.3s ease-in-out;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .collapse-box-business .collapse-inner-box {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      padding: 0rem 0.1rem;
      //   display: none;
      //   background-color: red;
      //   min-height: 75rem;

      .pick-up-delivery-details-box {
        display: flex;
        flex-direction: column;

        .label {
          margin-bottom: 0.4rem;
          font-size: 1.3rem;
          text-transform: capitalize;
        }

        .raven-loc-box {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 2rem;
          border-radius: 1.2rem;
          gap: 1rem;

          .address {
            font-size: 1.45rem;
          }

          .icon-box {
            .img-box {
              width: 3rem;
              height: 3rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .select-title-owner-type-box {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .select-box-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        .select-item {
          min-height: 10rem;
          border-radius: 1.2rem;
          display: flex;
          flex-direction: column;
          padding: 1rem 1rem;

          .img-icon-box {
            width: 4rem;
            border-radius: 50%;
            height: 4rem;

            .img-box {
              width: 4rem;
              height: 4rem;
              display: grid;
              place-items: center;
              border-radius: 50%;

              .img,
              svg {
                width: 50%;
                height: 50%;
                object-fit: contain;
              }
            }
          }

          .name {
            font-size: 1.25rem;
            margin-top: auto;
          }
        }
      }
    }

    .request-badge-box {
      margin-top: -2rem;
    }

    .info-box {
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 0.4rem;
        font-size: 1.3rem;
        text-transform: capitalize;
      }

      .increase-decrease-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        // margin-top: 1rem;
        border-width: 0.15rem;
        border-radius: 0.8rem;
        overflow: hidden;

        .increase-box,
        .decrease-box {
          width: 6.5rem;
          height: 5rem;
          display: grid;
          place-items: center;
          //   border-radius: 0.8rem;
          cursor: pointer;
        }

        .input-box {
          display: flex;
          justify-content: center;
          align-items: center;
          // gap: .5rem;
          width: 100%;
          font-weight: 700;

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          // input[type="number"] {
          //   -moz-appearance: textfield; /* Firefox */
          // }

          .input {
            border: none !important;
            outline: none;
            width: 100%;
            min-width: unset;
            padding: 0.75rem 0.2rem;
            border-radius: 0.8rem;
            font-weight: 700;
            // text-align: right;
            text-align: center;
            // back
            // display: none;

            &:focus {
              // border: .1rem solid red !important;
              margin-right: 0.5rem;
              outline: none;
            }
          }

          // .input-amount{
          //   width: 10rem;
          // }
        }

        .input-box-amount {
          width: 20rem;
        }

        .form-group .form-input {
          // background-color: transparent;
          // border: none !important;
          font-weight: 700;
        }
      }
    }
  }

  @media only screen and (max-width: 52em) {
    width: 90%;
    margin: 2rem auto;
  }

  .bankbox-img-display-box {
    width: 100%;
    height: 20rem;
    border-radius: 1.2rem;
    background-image: url("../../../assets/banbox-create.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 5rem center;
    // margin-bottom: 3rem;
  }

  .bankbox-img-display-box-virtual {
    background-image: url("../../../assets/virtual-img-terminal.png");
    background-position: center center;
  }

  .title-grey-wrapper-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .title {
      font-size: 1.6rem;
      font-weight: 500;
    }

    .label-value-wrapper-box {
      padding: 2rem 0rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      & > * {
        border-width: 0.15rem;
      }

      & > *:not(:last-child) {
        padding-bottom: 2rem;
      }

      & > *:last-child {
        border: none;
      }

      .item-list-row {
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        width: 100%;

        .title-text-box {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .title {
            font-size: 1.45rem;
            font-weight: 600;
          }

          .text {
            font-size: 1.25rem;
          }
        }

        .icon-box {
          .img-box {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            display: grid;
            place-items: center;

            .img {
              width: 65%;
              height: 65%;
              object-fit: contain;
            }
          }
        }

        .value-right {
          margin-left: auto;
          font-weight: 500;
        }
      }
    }
  }
}
