// @import "../breakpoints";

.header-nav-wrap {
  display: flex;
  align-items: center;
  width: 100%;

  .menu-icon-box {
    // display: none;
    @media only screen and (min-width: 56em) {
      display: none;
    }
    position: relative;
    z-index: 3;
    margin-right: 0.8rem;

    .icon {
      width: 2.7rem;
      height: 2.7rem;
    }
  }

  .mobile-logo-box {
    @media only screen and (min-width: 56em) {
      display: none;
    }
    position: relative;
    z-index: 2;

    .img-box {
      width: 4.5rem;
      height: 3rem;
      // transform: ;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .greeting-box {
    @media only screen and (max-width: 56em) {
      display: none;
    }
    .text {
      font-weight: 700;
      font-size: 1.7rem;

      span {
        color: #676767;
        font-weight: 400;
      }
    }
  }

  .profile-and-drop-wrap {
    position: relative;

    .profile-wrap {
      // border: 0.04rem solid #e5e6e6;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      padding: 0.5rem 0.8rem 0.5rem 0.6rem;
      border-radius: 12rem;
      cursor: pointer;
      position: relative;
      // background-color: red;

      @media only screen and (max-width: 56em) {
        border-radius: 50%;
        padding: 0.8rem;
        justify-content: center;
        border-width: 0.2rem;
      }

      .icon {
        margin-left: 2rem;

        @media only screen and (max-width: 56em) {
          display: none;
        }
      }

      .img-box {
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
        border-radius: 50%;
        overflow: hidden;
        // background-color: unsetx;

        @media only screen and (max-width: 56em) {
          margin-right: unset;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .name {
        font-size: 1.3rem;
        // color: red;
        font-weight: 600;

        @media only screen and (max-width: 56em) {
          display: none;
        }
      }
    }

    .drop-box-wrap {
      position: absolute;
      top: 115%;
      right: 0;
      width: 25rem;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: column;
      padding: 3rem 2rem 3rem 2rem;
      opacity: 1 !important;
      animation: moveDownSettingDrop 0.5s ease-in-out backwards;
      // transform: scale();

      & > *:not(:last-child) {
        margin-bottom: 2.5rem;
      }

      .business-select-switch {
        .react-select-class {
          & > *:nth-child(3) {
            background-color: rgba(247, 247, 247, 1);
            border: unset !important;
          }
        }
      }

      .child-row {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        .img-wrap {
          flex: 0 0 15%;
          // background-color: red;

          .img-box {
            width: 2.2rem;
            height: 2.2rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .text {
          font-size: 1.45rem;
          font-weight: 500;
          // margin-left: .8rem;
        }
      }

      // &>*:nth-child(2){
      //   .img-wrap{
      //     .img-box{
      //       width: 3rem;
      //       height: 3rem;
      //     }
      //   }
      // }

      // &>*:nth-last-child(2){
      //   .img-wrap{
      //     .img-box{
      //       width: 2rem;
      //       height: 2rem;
      //     }
      //   }
      // }

      // &>*:last-child{
      //   .img-wrap{
      //     .img-box{
      //       width: 2rem;
      //       height: 2rem;
      //     }
      //   }
      // }
    }
  }

  .theme-box {
    margin-left: auto;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0.6rem;
    border-radius: 10rem;
    // background-color: #f7f8f7;

    .box {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.8rem;
      border-radius: 1.6rem;
      cursor: pointer;
      transition: all 0.3s;

      .text {
        font-size: 1.25rem;
        font-weight: 600;
        margin-left: 0.25rem;

        @media only screen and (max-width: 42em) {
          display: none;
        }
      }

      .img-box {
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .box-active,
    .box-toggle-theme-active {
      //   background-color: #ffffff;
      box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04),
        0px 0px 2px rgba(51, 51, 51, 0.02);
    }
  }

  .notify-box {
    position: relative;
    margin-right: 2rem;
    position: relative;

    .notify-box-two {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      // background-color: #ffffff;
      // border: 0.04rem solid #e5e6e6;
      display: grid;
      place-items: center;
    }

    @media only screen and (max-width: 56em) {
      // display: none;
    }

    .red-dot {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      position: absolute;
      top: 0.65rem;
      right: 0.8rem;
      background-color: #f13d3d;
      z-index: 3;
    }

    .img-box-icon {
      width: 2.2rem;
      height: 2.2rem;
      display: grid;
      place-items: center;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:hover .img {
        transform: scale(1.1);
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 0.3s;
      }
    }

    .notification-big-screen-drop {
      position: absolute;
      top: 120%;
      right: 0%;
      animation: moveDownSettingDrop 0.5s ease-in-out backwards;
      z-index: 20;

      .notification-wrap-modal-index-big {
        border-radius: 1.2rem;
        max-height: 60rem;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        // min-height: 35rem;
        // background-color: #ffffff;
        width: 45rem;

        .no-content-reusuable-wrap-box {
          height: 25rem;
          padding: 0rem 2rem;
        }

        // &>*{
        //   padding: 0rem 2rem;
        // }

        .title-all-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
          padding: 0rem 2rem;

          .title {
            font-size: 2.3rem;
            font-weight: 600;
          }

          .all {
            font-size: 1.35rem;
            cursor: pointer;
            border-bottom: 0.1rem solid currentColor;
          }
        }

        .select-view-box {
          display: flex;
          align-items: center;
          gap: 2rem;
          border-width: 0.15rem;
          padding: 0rem 2rem;
          padding-bottom: 1.2rem;

          .select-view-item {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            position: relative;
            cursor: pointer;
            // padding: 0rem 2rem;

            &::after {
              content: "";
              top: 100%;
              height: 0.3rem;
              width: 100%;
              position: absolute;
              transform: translateY(1.1rem);
              // background-color: ;
              visibility: hidden;
            }

            .text {
              font-size: 1.5rem;
              // background-color: red;
            }

            .num {
              padding: 0.2rem 0.8rem;
              border-radius: 0.4rem;
              display: grid;
              place-items: center;
              opacity: 0.8;

              span {
                color: #ffffff;
                display: inline-block;
                font-size: 1rem;
                font-weight: 600;
              }
              // background-color: #020202;
            }
          }

          .select-view-item-active {
            &::after {
              visibility: visible;
            }
          }
        }

        .notification-content-box {
          display: flex;
          flex-direction: column;
          // margin-top: 1rem;
          max-height: 40rem;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-bottom: 3rem;

          &::-webkit-scrollbar {
            display: none;
          }

          &:hover .notify-item:not(:hover) {
            position: relative;
            z-index: 5;
          }

          &:hover .notify-item:hover {
            position: relative;
            z-index: 10;
          }

          .notify-item {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 2rem;
            border-width: 0.15rem;
            cursor: pointer;
            // padding: 0rem 2rem;
            // padding-bottom: 4rem;

            .img-wrap {
              .img-box {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                display: grid;
                place-items: center;

                .img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .description-box {
              display: flex;
              flex-direction: column;
              gap: 0.3rem;
              // background-color: red;

              .notify-text {
                font-size: 1.45rem;
                font-weight: 400;
                // color: red;
                max-width: 32rem;

                span {
                  font-weight: 600;
                }
              }
            }
          }
        }

        .view-all-box-wrap {
          margin-top: 3rem;
          align-self: center;
          text-align: center;
          cursor: pointer;
          font-weight: 500;
          margin-bottom: 1.5rem;
          // padding-bottom: 10rem;

          .text {
            margin-top: 1.5rem;
            font-size: 1.6rem;
          }

          .pagination-wrap__black-light {
            gap: 0rem;

            .child {
              transform: scale(0.75) !important;
            }
          }
        }
      }
    }
  }
}

@keyframes moveDownSettingDrop {
  0% {
    transform: translateY(-5%);
    opacity: 0.6;
    // visibility: hidden;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    // visibility: ;
  }
}
