.dashboard-pos-index-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.dashboard-pos-index-wrap .top-detail-card-box-new {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 1rem;
  background-color: unset;
}
@media only screen and (max-width: 55em) {
  .dashboard-pos-index-wrap .top-detail-card-box-new {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .dashboard-pos-index-wrap .top-detail-card-box-new > *:first-child {
    grid-row: 1/span 1;
    grid-column: 1/-1;
  }
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box {
  border-radius: 1.2rem;
  padding: 3rem 1rem 1rem 1rem;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .title .bottom-text-action {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  color: #755ae2;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .title .bottom-text-action:hover .icon {
  transform: translateY(0.1rem) translateX(0.15rem);
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .title .bottom-text-action .text {
  font-size: 1.25rem;
  color: currentColor;
  font-weight: 700;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .title .bottom-text-action .icon {
  transform: translateY(0.1rem);
  color: currentColor;
  transition: all 0.3s;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .value-box {
  padding: 1.5rem 1rem 1.5rem 2rem;
  border-radius: 0.8rem;
}
.dashboard-pos-index-wrap .top-detail-card-box-new .box .value-box .value {
  font-weight: 700;
  font-size: 1.75rem;
}

.top-up-pos-modal-wrap-box {
  margin-top: -1rem;
}
.top-up-pos-modal-wrap-box .wrap {
  flex: 1;
  border-radius: 0.8rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box {
  display: flex;
  align-items: center;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .label-value-box {
  margin-right: auto;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .label-value-box .label {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .label-value-box .value {
  font-weight: 600;
  font-size: 1.5rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box:hover {
  transform: scale(1.03);
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box span {
  font-size: 1.1rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box-active {
  background-color: #020202 !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: rgba(26, 206, 55, .3); */
  gap: 0.4rem;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box-active:hover {
  transform: unset;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box-active span {
  color: #ffffff;
  display: inline-block;
}
.top-up-pos-modal-wrap-box .wrap .bttom-wrap .box .copy-box-active span .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.view-pos-cashier-modal-wrap {
  display: flex;
  flex-direction: column;
  max-height: 55rem;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.view-pos-cashier-modal-wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.view-pos-cashier-modal-wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.view-pos-cashier-modal-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.view-pos-cashier-modal-wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.view-pos-cashier-modal-wrap::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.view-pos-cashier-modal-wrap .wrap {
  display: flex;
  flex-direction: column;
  border: unset;
  gap: 1.3rem;
  max-height: 40rem;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.view-pos-cashier-modal-wrap .wrap::-webkit-scrollbar:horizontal {
  display: none;
}
.view-pos-cashier-modal-wrap .wrap::-webkit-scrollbar {
  width: 0.5rem;
}
.view-pos-cashier-modal-wrap .wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.view-pos-cashier-modal-wrap .wrap::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 30rem;
}
.view-pos-cashier-modal-wrap .wrap::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
.view-pos-cashier-modal-wrap .wrap > *:not(:last-child) {
  padding-bottom: 1.3rem;
}
.view-pos-cashier-modal-wrap .wrap > *:last-child {
  border: unset;
}
.view-pos-cashier-modal-wrap .child-row {
  display: flex;
  align-items: center;
}
.view-pos-cashier-modal-wrap .child-row .icon-wrap {
  display: grid;
  place-items: center;
}
.view-pos-cashier-modal-wrap .child-row .icon-wrap .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.view-pos-cashier-modal-wrap .child-row .icon-wrap .img-box .img {
  width: 75%;
  height: 75%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.view-pos-cashier-modal-wrap .child-row .text-box {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  align-items: flex-start;
}
.view-pos-cashier-modal-wrap .child-row .text-box .name {
  font-weight: 700;
  margin-bottom: 0.25rem;
  text-transform: capitalize;
}
.view-pos-cashier-modal-wrap .child-row .text-box .number {
  font-size: 1.2rem;
  text-align: left;
}
.view-pos-cashier-modal-wrap .child-row .delete-wrap {
  flex: 0 0 10%;
  margin-left: auto;
  display: grid;
  place-items: center;
}
.view-pos-cashier-modal-wrap .child-row .delete-wrap .img-box {
  background-color: unset;
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.view-pos-cashier-modal-wrap .child-row .delete-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}

.bottom-content-box-pos-index-new {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.bottom-content-box-pos-index-new .table-content-pos {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 10rem;
}
.bottom-content-box-pos-index-new .table-content-pos::-webkit-scrollbar {
  display: none;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.bottom-content-box-pos-index-new .table-content-pos .status-type-wrap-disabled {
  background-color: #f7f8f7;
}
.bottom-content-box-pos-index-new .table-body-info-wrap {
  flex: 1;
  overflow-y: scroll;
}
.bottom-content-box-pos-index-new .table-body-info-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .bottom-content-box-pos-index-new .table-body-info-wrap {
    flex: unset;
  }
}
.bottom-content-box-pos-index-new .type-text-box {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
}
.bottom-content-box-pos-index-new .type-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.bottom-content-box-pos-index-new .type-text-box .text-box .text {
  font-size: 1.55rem;
  font-weight: 600;
}
.bottom-content-box-pos-index-new .type-text-box .text-box .text-small {
  font-size: 1.3rem;
  font-weight: 400 !important;
}
.bottom-content-box-pos-index-new .type-text-box .img-wrap {
  display: grid;
  place-items: center;
}
.bottom-content-box-pos-index-new .type-text-box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  background-color: #1ace37;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.bottom-content-box-pos-index-new .type-text-box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.bottom-content-box-pos-index-new .type-text-box .img-wrap .img-box-debit {
  background-color: #ff0f00;
}
.bottom-content-box-pos-index-new .type-text-box .img-wrap .img-box-failed {
  background-color: #cccccc;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box {
  width: 100%;
  display: flex;
  align-items: center;
  border-width: 0.15rem;
  padding-bottom: 1rem;
  min-height: 6rem;
  margin-top: -1rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option {
  cursor: pointer;
  position: relative;
  transform: translateY(0.7rem);
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option > * {
  position: relative;
  z-index: 3;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option::after {
  content: "";
  position: absolute;
  height: 0.5rem;
  width: 100%;
  background-color: #020202;
  bottom: 0;
  left: 0;
  transform: translateY(1.8rem);
  visibility: hidden;
  border-radius: 3rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option-active {
  cursor: default;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option-active::after {
  visibility: visible;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .all-select-box .select-view-wrap .option-active span {
  color: #020202;
  font-weight: 500;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .search-group {
  margin-right: auto;
  width: 35rem;
}
@media only screen and (max-width: 57em) {
  .bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .search-group {
    margin-bottom: 1.5rem;
    margin-right: unset;
    width: 100%;
  }
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap {
  width: -moz-max-content;
  width: max-content;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  overflow: unset;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child {
  padding: unset;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child > *:nth-child(2) input {
  min-width: unset !important;
  grid-area: unset !important;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) {
  padding: unset;
  margin-left: 0.7rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  width: 1.5rem;
  height: 1.5rem;
}
.bottom-content-box-pos-index-new .all_terminal_transcation_filter-box .search-filter-box-pos .pos-select-wrap .react-select-class > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  left: unset;
  right: 0;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 2rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card {
  padding: 1rem 1.6rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  border-width: 0.15rem;
  gap: 1rem;
  cursor: pointer;
  width: 32rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .icon-wrap {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  position: relative;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .icon-wrap .img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .icon-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .icon-wrap .img-box-virtual {
  width: 70%;
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .label-serial-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .label-serial-box .label {
  text-transform: capitalize;
  font-size: 1.7rem;
  font-weight: 600;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .icon-label-serial-box .label-serial-box .serial {
  font-size: 1.25rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .cash-in-box-wrap {
  padding: 1rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .cash-in-box-wrap .label {
  font-size: 1.35rem;
  text-transform: uppercase;
  font-weight: 600;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card .cash-in-box-wrap .value {
  font-size: 1.75rem;
  font-weight: 500;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending {
  position: relative;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending .pending-tractk-box {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending .pending-tractk-box .terminal-badge {
  border-radius: 0.8rem;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending .pending-tractk-box .terminal-badge span {
  font-weight: 600;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending .icon-label-serial-box {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
}
.bottom-content-box-pos-index-new .all-pending-active-terminals-box-wrapper .terminal-card-pending .cash-in-box-wrap {
  display: none;
}
.bottom-content-box-pos-index-new .content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 55em) {
  .bottom-content-box-pos-index-new .content-title {
    flex-direction: column;
    align-items: flex-start;
  }
  .bottom-content-box-pos-index-new .content-title .text-select-box {
    align-self: flex-end;
  }
}
.bottom-content-box-pos-index-new .content-title .text-select-box {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .text {
  font-weight: 600;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap {
  width: -moz-max-content;
  width: max-content;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  overflow: unset;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child {
  padding: unset;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:first-child > *:nth-child(2) input {
  min-width: unset !important;
  grid-area: unset !important;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) {
  padding: unset;
  margin-left: 0.7rem;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) svg {
  width: 1.5rem;
  height: 1.5rem;
}
.bottom-content-box-pos-index-new .content-title .text-select-box .pos-select-wrap .react-select-class > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  left: unset;
  right: 0;
}
.bottom-content-box-pos-index-new .terminal-show-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-bottom: 4rem;
}
@media only screen and (max-width: 42em) {
  .bottom-content-box-pos-index-new .terminal-show-box {
    grid-template-columns: 1fr;
  }
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  padding: 1.5rem 2rem;
  cursor: pointer;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .top-img-box {
  height: 15rem;
  display: none;
  width: 100%;
  background-image: url("../../../assets/raven-terminal-bg-img.png");
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.8rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box {
  display: flex;
  gap: 5rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .img-wrap {
  display: grid;
  place-items: center;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  background-color: #1ace37 !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .img-wrap .img-box-debit {
  background-color: #ff0f00 !important;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .img-wrap .img-box-failed {
  background-color: #f7f8f7;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .text-box .label {
  font-size: 1.15rem;
  text-transform: uppercase;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .text-box .value {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 0.3rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .text-box .income {
  color: #1ace37;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .income-expense-box .box .text-box .expense {
  color: #ff0f00;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  margin: 1.5rem 0rem;
  padding-bottom: 1.5rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box .label {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box .label span {
  display: inline-block;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box .title-box {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box .title-box .title {
  font-weight: 700;
  font-size: 1.7rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .title-label-box .title-box span {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  display: none;
  background-color: #1ace37;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .status-type-wrap {
  background-color: #e8fff6;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  text-transform: capitalize;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .status-type-wrap span {
  font-size: 1.2rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .status-type-wrap-success {
  color: #1ace37;
}
.bottom-content-box-pos-index-new .terminal-show-box .terminal-wrap-box .middle-content-box .status-type-wrap-disabled {
  background-color: #f7f8f7;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  padding: 1.5rem 2rem;
  cursor: pointer;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .label-serial-no-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .label-serial-no-box .label {
  font-size: 2rem;
  font-weight: 600;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .label-serial-no-box .serial-no {
  font-size: 1.3rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap {
  padding: 2.5rem 1.5rem;
  border-radius: 1.2rem;
  margin-top: 1.5rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .status-type-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
  background-color: transparent !important;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .status-type-wrap .box {
  padding: 0.8rem 1.5rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .status-type-wrap .box .text {
  font-size: 1.45rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .status-type-wrap .box .img-wrap .img-box {
  width: 2rem;
  height: 2rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .status-type-wrap .box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box .box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box .box .value {
  font-weight: 700;
  font-size: 2rem;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box .box .income {
  color: #1ace37;
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box .box .expense {
  color: rgb(255, 15, 0);
}
.bottom-content-box-pos-index-new .terminal-show-box .new-terminal-child-box .income-expense-wrap .income-expense-box .income-box {
  padding-right: 1.5rem;
}

.dashboard-pos-index-wrap .top-detail-card-box {
  min-height: 20rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(208, 213, 221, 0.5);
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box {
    flex-direction: column;
    gap: 2rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box {
  flex: 0 0 57%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .left-box {
    flex: unset;
    width: 100%;
    gap: 1.5rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.5rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box .box .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box .box .value {
  font-weight: 600;
  font-size: 2rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .left-box .wallet-cash-box .box .value {
    font-size: 1.5rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .btn-two-box {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .left-box .btn-two-box {
    margin-top: 1.5rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .left-box .btn-two-box .raven-btn {
  min-width: 19rem;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .left-box .btn-two-box .raven-btn {
    min-width: unset;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box {
  flex: 1;
  display: flex;
}
@media only screen and (max-width: 52em) {
  .dashboard-pos-index-wrap .top-detail-card-box .right-box {
    flex: unset;
    order: -1;
    min-height: 20rem;
  }
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap {
  flex: 1;
  border-radius: 0.8rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .top-wrap {
  padding: 1.8rem 2rem;
  background-color: rgba(204, 204, 204, 0.2);
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .top-wrap .text {
  font-size: 1.3rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0rem 2rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box {
  display: flex;
  align-items: center;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box {
  margin-right: auto;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box .label {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box .value {
  font-weight: 600;
  font-size: 1.5rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box:hover {
  transform: scale(1.03);
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box span {
  font-size: 1.1rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active {
  background-color: #020202 !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: rgba(26, 206, 55, .3); */
  gap: 0.4rem;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active:hover {
  transform: unset;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active span {
  color: #ffffff;
  display: inline-block;
}

.dashboard-pos-index-wrap .top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active span .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.bottom-content-box-pos-index {
  width: 100%;
  padding: 2rem;
  margin: 0rem auto;
}
@media only screen and (max-width: 48em) {
  .bottom-content-box-pos-index {
    padding: unset;
    margin: 3rem auto 5rem auto;
    width: 90%;
  }
}
@media only screen and (max-width: 48em) {
  .bottom-content-box-pos-index .table {
    display: none;
  }
}

.bottom-content-box-pos-index .status-type-wrap {
  background-color: #e8fff6;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  text-transform: capitalize;
}

.bottom-content-box-pos-index .status-type-wrap span {
  font-size: 1.2rem;
}

.bottom-content-box-pos-index .status-type-wrap-success {
  color: #1ace37;
}

.bottom-content-box-pos-index .status-type-wrap-disabled {
  background-color: #f7f8f7;
}

.cashier-assign-modal-wrap .step-progress-wrap-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  width: 80%;
  margin: 2rem 0rem;
}
.cashier-assign-modal-wrap .step-progress-wrap-box .progress-bar-wrap-reuseable {
  background-color: #d1d1d1;
  cursor: pointer;
}
.cashier-assign-modal-wrap .step-process-wrap-box {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .note-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .note-box-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .note-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.cashier-assign-modal-wrap .step-process-wrap-box .note-box-wrap .text {
  font-size: 1.2rem;
  color: rgb(234, 135, 45);
}
.cashier-assign-modal-wrap .step-process-wrap-box .back-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 2rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .back-box:hover .icon {
  animation: moveBackFort 0.3s ease-in-out;
}
.cashier-assign-modal-wrap .step-process-wrap-box .back-box .icon {
  width: 1.45rem;
  height: 1.45rem;
  margin-right: 0.1rem;
  fill: rgba(80, 95, 109, 0.32);
  transform: scale(1.1);
}
.cashier-assign-modal-wrap .step-process-wrap-box .back-box .text {
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 600;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box .num {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: rgba(189, 189, 189, 0.2);
  display: grid;
  place-items: center;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box .num span {
  color: rgba(22, 45, 76, 0.4);
  font-size: 1.2rem;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box .text {
  margin-top: 0.1rem;
  color: #bdbdbd;
  font-size: 1.2rem;
}
@media only screen and (max-width: 42em) {
  .cashier-assign-modal-wrap .step-process-wrap-box .step-box .box .text {
    display: none;
  }
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box-active .num {
  background-color: #333333;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box-active .num span {
  color: #ffffff;
  font-weight: 600;
}
.cashier-assign-modal-wrap .step-process-wrap-box .step-box .box-active .text {
  color: #020202;
}
@media only screen and (max-width: 42em) {
  .cashier-assign-modal-wrap .step-process-wrap-box .step-box .box-active .text {
    display: none;
  }
}

.cashier-assign-modal-wrap .title {
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
}

.cashier-assign-modal-wrap .text {
  font-size: 1.45rem;
  width: 90%;
}

.cashier-assign-modal-wrap .underline {
  width: 100%;
  height: 0.1rem;
  background-color: #f0eeff;
  margin: 1.5rem 0rem;
}

.cashier-assign-modal-wrap .form-inputs-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 37rem;
  overflow-y: scroll;
  padding: 0rem 0.1rem 3rem 0.1rem;
}
.cashier-assign-modal-wrap .form-inputs-wrap::-webkit-scrollbar {
  display: none;
}

.terminal-information-top-detail-card-box {
  min-height: 20rem;
  margin-bottom: 1rem;
  box-shadow: -1px 0px 20px rgba(229, 230, 230, 0.35);
  border-radius: 1.6rem;
  padding: 1.5rem 2rem;
  display: flex;
}
@media only screen and (max-width: 52em) {
  .terminal-information-top-detail-card-box {
    flex-direction: column;
    min-height: unset;
  }
}

.terminal-information-top-detail-card-box .left-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-right: auto;
}
@media only screen and (max-width: 52em) {
  .terminal-information-top-detail-card-box .left-box {
    flex: unset;
    width: 100%;
    gap: 1.5rem;
  }
}

.terminal-information-top-detail-card-box .left-box .top-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 52em) {
  .terminal-information-top-detail-card-box .left-box .top-label-box {
    flex: unset;
    width: 100%;
    gap: 1.5rem;
  }
}

.terminal-information-top-detail-card-box .left-box .top-label-box .wrap-filter {
  width: 10rem;
  position: relative;
  z-index: 10;
}

.terminal-information-top-detail-card-box .left-box .top-label-box .wrap-filter .react-select-class > *:nth-child(3) {
  padding: 0rem;
  flex: unset;
  justify-content: space-evenly;
}

.terminal-information-top-detail-card-box .left-box .top-label-box .wrap-filter .react-select-class > *:nth-child(3) > *:first-child {
  padding-right: 0rem;
  flex: unset;
}

.terminal-information-top-detail-card-box .left-box .top-label-box .wrap-filter .react-select-class > *:nth-child(3) > *:nth-child(2) > *:nth-child(2) {
  padding: unset;
}

.terminal-information-top-detail-card-box .left-box .top-label-box .wrap-filter .react-select-class > *:nth-child(4) {
  left: 0;
}

.terminal-information-top-detail-card-box .left-box .wallet-cash-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}
@media only screen and (max-width: 52em) {
  .terminal-information-top-detail-card-box .left-box .wallet-cash-box {
    grid-template-columns: repeat(2, 1fr);
  }
}

.terminal-information-top-detail-card-box .left-box .wallet-cash-box > *:not(:last-child) {
  border-right: 0.1rem solid #f0eeff;
  padding-right: 3rem;
}

.terminal-information-top-detail-card-box .left-box .wallet-cash-box .box .label {
  font-size: 1.1rem !important;
  margin-top: 0.3rem;
}

.terminal-information-top-detail-card-box .left-box .wallet-cash-box .box .value {
  font-weight: 600;
  font-size: 2rem;
}

.terminal-information-top-detail-card-box .right-box {
  flex: 0 0 43%;
  display: flex;
}
@media only screen and (max-width: 52em) {
  .terminal-information-top-detail-card-box .right-box {
    flex: unset;
    width: 100%;
    order: -1;
    margin-bottom: 2.5rem;
  }
}

.terminal-information-top-detail-card-box .right-box .wrap {
  flex: 1;
  border-radius: 0.8rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.terminal-information-top-detail-card-box .right-box .wrap .top-wrap {
  padding: 1.8rem 2rem;
  background-color: rgba(204, 204, 204, 0.2);
}

.terminal-information-top-detail-card-box .right-box .wrap .top-wrap .text {
  font-size: 1.3rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0rem 2rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box {
  display: flex;
  align-items: center;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box {
  margin-right: auto;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box .label {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .label-value-box .value {
  font-weight: 600;
  font-size: 1.5rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box {
  box-shadow: 0px 3px 4px rgba(51, 51, 51, 0.04), 0px 0px 2px rgba(51, 51, 51, 0.02);
  padding: 0.55rem 1.3rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box:hover {
  transform: scale(1.03);
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box span {
  font-size: 1.1rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active {
  background-color: #020202;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active:hover {
  transform: unset;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active span {
  color: #ffffff;
  display: inline-block;
}

.terminal-information-top-detail-card-box .right-box .wrap .bttom-wrap .box .copy-box-active span .icon {
  margin-right: 0.5rem;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  transform: translateY(0.06rem);
}

.terminal-info-table-wrap-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
}
.terminal-info-table-wrap-box::-webkit-scrollbar {
  display: none;
}
.terminal-info-table-wrap-box .terminal-table-sort-box {
  padding: 0rem 3rem;
  margin: 2rem 0rem;
}
.terminal-info-table-wrap-box .terminal-table-sort-box .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
}
.terminal-info-table-wrap-box .terminal-table-sort-box .title-text-box .text {
  font-size: 1.25rem;
}
.terminal-info-table-wrap-box .status-type-wrap {
  background-color: #e9f5ff;
  border-radius: 3rem;
  width: -moz-max-content;
  width: max-content;
  padding: 0.8rem 1.2rem;
  color: #476885;
}
.terminal-info-table-wrap-box .status-type-wrap span {
  font-weight: 400 !important;
  font-size: 1.2rem;
}
.terminal-info-table-wrap-box .status-type-wrap-fail {
  background-color: #fff5f5;
  color: #ff0f00;
}
.terminal-info-table-wrap-box .status-type-wrap-success {
  background-color: #e8fff6;
  color: #1ace37;
}
.terminal-info-table-wrap-box .status-type-wrap-pending {
  background-color: #fff6ed;
  color: #ea872d;
}
.terminal-info-table-wrap-box .status-type-wrap-disabled {
  background-color: #f7f8f7;
}
@media only screen and (max-width: 48em) {
  .terminal-info-table-wrap-box {
    width: 90%;
    margin: 2rem auto;
  }
}
@media only screen and (max-width: 48em) {
  .terminal-info-table-wrap-box .table {
    display: none;
  }
}
.terminal-info-table-wrap-box .transaction-action-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.terminal-info-table-wrap-box .transaction-action-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.3s ease-in-out;
}
.terminal-info-table-wrap-box .transaction-action-wrap .img-box:hover {
  transform: scale(1.05);
}
.terminal-info-table-wrap-box .transaction-action-wrap .img-box .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.terminal-info-table-wrap-box .transaction-action-wrap > *:nth-child(2) .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}

.terminal-info-table-wrap-box .type-text-box {
  display: flex;
  align-items: center;
  width: -moz-max-content;
  width: max-content;
}
.terminal-info-table-wrap-box .type-text-box .text-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.terminal-info-table-wrap-box .type-text-box .text-box .text {
  font-size: 1.55rem;
  font-weight: 600;
}
.terminal-info-table-wrap-box .type-text-box .text-box .text-small {
  font-size: 1.3rem;
  font-weight: 400 !important;
}
.terminal-info-table-wrap-box .type-text-box .img-wrap {
  display: grid;
  place-items: center;
}
.terminal-info-table-wrap-box .type-text-box .img-wrap .img-box-cre-deb {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  background-color: #1ace37;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex: 0 0 5%;
}
.terminal-info-table-wrap-box .type-text-box .img-wrap .img-box-cre-deb .img {
  width: 35%;
  height: 35%;
  -o-object-fit: contain;
     object-fit: contain;
  filter: brightness(1000%);
}
.terminal-info-table-wrap-box .type-text-box .img-wrap .img-box-debit {
  background-color: #ff0f00;
}
.terminal-info-table-wrap-box .type-text-box .img-wrap .img-box-failed {
  background-color: #cccccc;
}

.terminal-info-table-wrap-box .title-sort-text-box {
  display: flex;
  align-items: center;
  padding: 1rem 3rem;
  margin: 1rem 0rem;
}
@media only screen and (max-width: 52em) {
  .terminal-info-table-wrap-box .title-sort-text-box {
    flex-direction: column;
    padding: 1rem 1.5rem;
    gap: 1.3rem;
  }
}
@media only screen and (max-width: 48em) {
  .terminal-info-table-wrap-box .title-sort-text-box {
    padding: 0rem;
  }
}

.terminal-info-table-wrap-box .title-sort-text-box .title {
  margin-right: auto;
  font-weight: 700;
  font-size: 1.5rem;
}

.terminal-info-table-wrap-box .title-sort-text-box .title-text {
  margin-right: auto;
}

.terminal-info-table-wrap-box .title-sort-text-box .title-text .text {
  font-size: 1.25rem;
}

.terminal-info-table-wrap-box .title-sort-text-box .title-text .title {
  font-weight: 700;
  font-size: 1.5rem;
}

.terminal-info-table-wrap-box .title-sort-text-box .fitler-box {
  display: flex;
  gap: 2rem;
  align-items: center;
}
@media only screen and (max-width: 48em) {
  .terminal-info-table-wrap-box .title-sort-text-box .fitler-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 2rem;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

.terminal-info-table-wrap-box .title-sort-text-box .fitler-box .reset-text {
  color: var(--primary-purple);
  font-weight: 600;
  cursor: pointer;
  min-width: 9rem;
}

.terminal-info-table-wrap-box .title-sort-text-box .fitler-box .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0;
}

.terminal-info-table-wrap-box .title-sort-text-box .fitler-box .filter-by-source .react-select-class > *:nth-child(3) {
  min-width: unset;
  width: -moz-max-content;
} 
.verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.verify-name-box > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}

.verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}

.cashier-assign-modal-wrap-settings .underline {
  margin-bottom: 3rem;
}
.cashier-assign-modal-wrap-settings .form-inputs-wrap {
  margin-top: 2rem;
}
.cashier-assign-modal-wrap-settings .verify-name-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.cashier-assign-modal-wrap-settings .verify-name-box > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.cashier-assign-modal-wrap-settings .verify-name-box .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}
.cashier-assign-modal-wrap-settings .verify-name-box-show {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
.cashier-assign-modal-wrap-settings .detail-tex-setting-director-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.cashier-assign-modal-wrap-settings .detail-tex-setting-director-box .box {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 1fr;
  font-size: 1.25rem;
}
.cashier-assign-modal-wrap-settings .detail-tex-setting-director-box .box .value {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-weight: 700;
}
.cashier-assign-modal-wrap-settings .detail-tex-setting-director-box .box .value .num {
  flex: 0 0 55%;
}
.cashier-assign-modal-wrap-settings .detail-tex-setting-director-box .box .value span {
  display: inline-block;
  color: #755ae2;
  cursor: pointer;
}

.terminal-info-table-wrap-box {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin: 2rem 0rem;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .all-text {
  font-weight: 600;
  font-size: 2rem;
  margin-right: auto;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
@media only screen and (max-width: 42em) {
  .terminal-info-table-wrap-box .new-search-filter-chart-box .chart-hide-show-box .hide-show-box {
    display: none;
  }
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .chart-hide-show-box .hide-show-box > *:last-child {
  transform: scale(0.8);
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .chart-hide-show-box .hide-show-box span {
  width: 1.8rem;
  height: 1.8rem;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box {
  display: flex;
  align-items: stretch;
  justify-content: unset;
  gap: 1.5rem;
  gap: 2rem;
  position: relative;
  z-index: 100;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .badge-filter-option-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 2rem;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .badge-filter-option-box .option-box .icon {
  cursor: pointer;
  transform: translateY(0.1rem);
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .export-table-content-box {
  display: grid;
  place-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .export-table-content-box:hover .img-box {
  transform: translateY(0.4rem);
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box {
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.3s;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .export-table-content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box {
  position: relative;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .drop-box-wrap {
  position: absolute;
  border-radius: 1.2rem;
  top: 110%;
  right: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  gap: 2rem;
  animation: moveDownSettingDrop 0.2s ease-in-out backwards;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box {
  margin: auto 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 1.8rem;
  align-items: center;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box span {
  display: inline-block;
  height: 0.15rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #676767;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:nth-child(2) {
  width: 70%;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .line-box > *:last-child {
  width: 40%;
}
.terminal-info-table-wrap-box .new-search-filter-chart-box .search-filter-box .filter-drop-box .filter-box .text {
  font-weight: 700;
}
.terminal-info-table-wrap-box .table-content-pos {
  flex: 1;
  overflow-y: scroll;
}
.terminal-info-table-wrap-box .table-content-pos::-webkit-scrollbar {
  display: none;
}
.terminal-info-table-wrap-box .table-body-info-wrap {
  flex: 1;
  overflow-y: scroll;
}
.terminal-info-table-wrap-box .table-body-info-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 48em) {
  .terminal-info-table-wrap-box .table-body-info-wrap {
    flex: unset;
  }
}

.pos-settlement-wrap-modal {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.pos-settlement-wrap-modal .title-text-box .title {
  font-weight: 700;
  font-size: 1.75rem;
}
.pos-settlement-wrap-modal .title-text-box .text {
  font-size: 1.35rem;
}
.pos-settlement-wrap-modal .content-box {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.pos-settlement-wrap-modal .content-box .accout-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 2.5rem 1.5rem;
  grid-row-gap: 2rem;
  background-image: url("../../../assets/wire-frame-bg-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 1.2rem;
}
.pos-settlement-wrap-modal .content-box .accout-wrap > *:last-child {
  grid-column: 1/-1;
}
.pos-settlement-wrap-modal .content-box .accout-wrap .box .label {
  font-size: 1.3rem;
}
.pos-settlement-wrap-modal .content-box .accout-wrap .box .value {
  font-weight: 700;
  font-size: 1.3rem;
}
.pos-settlement-wrap-modal .content-box .select-type-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
}
.pos-settlement-wrap-modal .content-box .select-type-box .type-child {
  position: relative;
  padding-bottom: 1.2rem;
  cursor: pointer;
}
.pos-settlement-wrap-modal .content-box .select-type-box .type-child::after {
  content: "";
  position: absolute;
  top: 90%;
  left: 0;
  height: 0.25rem;
  width: 100%;
  border-radius: 1rem;
}
.pos-settlement-wrap-modal .content-box .select-type-box .type-child span {
  position: relative;
  z-index: 2;
  font-size: 1.45rem;
}
.pos-settlement-wrap-modal .content-box .select-type-box .type-child-active span {
  font-weight: 700;
}
.pos-settlement-wrap-modal .content-box .form-input-wraps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.pos-settlement-wrap-modal .content-box .form-input-wraps .note-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}
.pos-settlement-wrap-modal .content-box .form-input-wraps .note-box-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.pos-settlement-wrap-modal .content-box .form-input-wraps .note-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.pos-settlement-wrap-modal .content-box .form-input-wraps .note-box-wrap .text {
  font-size: 1.2rem;
  color: rgb(234, 135, 45);
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: space-between;
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box > *:last-child {
  align-self: center;
  transform: scale(0.8);
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box .text-info-box {
  display: flex;
  align-items: center;
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box .text-info-box .img-box {
  width: 1.2rem;
  height: 1.2rem;
  display: grid;
  place-items: center;
  margin-left: 0.7rem;
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box .text-info-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.pos-settlement-wrap-modal .content-box .toggle-slide-text-box .text-info-box .text {
  font-size: 1.2rem;
}

.top-pos-single-detail-card-box-new {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-bottom: 1rem;
  background-color: unset;
}
@media only screen and (max-width: 55em) {
  .top-pos-single-detail-card-box-new {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .top-pos-single-detail-card-box-new > *:first-child {
    grid-row: 1/span 1;
    grid-column: 1/-1;
  }
}
.top-pos-single-detail-card-box-new .box {
  border-radius: 1.2rem;
  padding: 3rem 1rem 1rem 1rem;
}
.top-pos-single-detail-card-box-new .box .title {
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-pos-single-detail-card-box-new .box .title .bottom-text-action {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  color: #755ae2;
}
.top-pos-single-detail-card-box-new .box .title .bottom-text-action:hover .icon {
  transform: translateY(0.1rem) translateX(0.15rem);
}
.top-pos-single-detail-card-box-new .box .title .bottom-text-action .text {
  font-size: 1.25rem;
  color: currentColor;
  font-weight: 700;
}
.top-pos-single-detail-card-box-new .box .title .bottom-text-action .icon {
  transform: translateY(0.1rem);
  color: currentColor;
  transition: all 0.3s;
}
.top-pos-single-detail-card-box-new .box .value-box {
  padding: 1.5rem 1rem 1.5rem 2rem;
  border-radius: 0.8rem;
}
.top-pos-single-detail-card-box-new .box .value-box .value {
  font-weight: 700;
  font-size: 1.75rem;
}
.top-pos-single-detail-card-box-new .card-terminal-info {
  display: flex;
  flex-direction: column;
}
.top-pos-single-detail-card-box-new .card-terminal-info .terminal-detail-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: 2rem;
}
.top-pos-single-detail-card-box-new .card-terminal-info .terminal-detail-box .terminal-label {
  font-size: 1.35rem;
}
.top-pos-single-detail-card-box-new .card-terminal-info .terminal-detail-box .account-no {
  font-size: 1.8rem;
  font-weight: 600;
}
.top-pos-single-detail-card-box-new .card-terminal-info .terminal-detail-box .account-no span {
  font-size: 1.35rem;
  font-weight: 400;
}
.top-pos-single-detail-card-box-new .card-terminal-info .img-wrap {
  margin-bottom: auto;
  width: -moz-max-content;
  width: max-content;
  margin-top: -2rem;
}
.top-pos-single-detail-card-box-new .card-terminal-info .img-wrap .img-box {
  width: 5rem;
  height: 5rem;
  display: grid;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 50%;
  overflow: hidden;
}
.top-pos-single-detail-card-box-new .card-terminal-info .img-wrap .img-box .img {
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}

.verify-name-box-beneficiary-settings {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: -1rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
}
.verify-name-box-beneficiary-settings > *:first-child .label-check {
  border-radius: 50%;
  transform: scale(0.8);
}
.verify-name-box-beneficiary-settings .text {
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 0.5rem;
}

.verify-name-box-show-beneficiary-settings {
  max-height: 5rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}

.add-role-to-for-sub-account-modal-wrap {
  max-height: 60rem;
  overflow-y: scroll;
}
.add-role-to-for-sub-account-modal-wrap::-webkit-scrollbar {
  display: none;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box {
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  border-width: 0.15rem;
  max-height: 50rem;
  padding-bottom: 1rem;
  margin-bottom: 10rem;
  overflow-y: scroll;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box::-webkit-scrollbar {
  display: none;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box {
  display: flex;
  flex-direction: column;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box > * {
  padding: 0rem 1.5rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .top-box .role-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem 0rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .top-box .role-box .check-box-wrap .input-check:checked ~ .label-check__black-light {
  background-color: #755ae2 !important;
  border-color: #755ae2 !important;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .top-box .role-box .check-box-wrap .label-check__black-light {
  border: 0.15rem solid rgb(204, 204, 204) !important;
  width: 2.5rem;
  height: 2.5rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .top-box .role-box .text {
  text-transform: capitalize;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .underline {
  border-width: 0.12rem;
  margin-top: 2rem;
  margin-bottom: unset;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transform-origin: top;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  width: 100%;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box::-webkit-scrollbar {
  display: none;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box > *:not(:last-child) {
  padding-bottom: 2rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  cursor: pointer;
  border-width: 0.15rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .avatar-box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #020202 !important;
  display: grid;
  place-items: center;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .avatar-box span {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .name-role-box {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .name-role-box .name {
  font-size: 1.45rem;
  font-weight: 600;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .name-role-box .role {
  font-size: 1.1rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .img-wrap .img-box {
  width: 4rem;
  height: 4rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .select-option-wrap {
  margin-left: auto;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(3) {
  width: -moz-max-content !important;
  width: max-content !important;
  border: unset !important;
  border-radius: 3rem;
  background-color: rgb(247, 247, 247);
  padding: 0.03rem 0.3rem;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box .select-item .select-option-wrap form .form-group .react-select-class > *:nth-child(4) {
  left: unset;
  right: 0.5rem;
  width: -moz-max-content;
  width: max-content;
}
.add-role-to-for-sub-account-modal-wrap .role-select-wrap-box .role-and-user-box .bottom-box-show {
  gap: 2rem;
  overflow-x: visible;
  overflow-y: visible;
  margin-top: 2rem;
  max-height: -moz-max-content;
  max-height: max-content;
  transition: all 0.3s linear-bezier(0, 1.05, 0, 1);
}

.note-box-wrap-team-invite {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}
.note-box-wrap-team-invite .img-box {
  width: 3rem;
  height: 3rem;
}
.note-box-wrap-team-invite .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.note-box-wrap-team-invite .text {
  font-size: 1.2rem;
  color: rgb(234, 135, 45);
}/*# sourceMappingURL=DashboardPosIndex.css.map */