.alert-receiver-modal-wrap-index {
  display: flex;
  flex-direction: column;
  min-height: 35rem;
  max-height: 60rem;
}
.alert-receiver-modal-wrap-index .loading-wrap {
  width: 100%;
  height: 35rem;
  display: grid;
  place-items: center;
}
.alert-receiver-modal-wrap-index .loading-wrap .img-box {
  width: 50%;
  height: 50%;
}
.alert-receiver-modal-wrap-index .loading-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .title-text-box {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
.alert-receiver-modal-wrap-index .title-text-box .title {
  font-weight: 600;
  font-size: 2.3rem;
}
.alert-receiver-modal-wrap-index .title-text-box .text {
  color: rgb(103, 103, 103);
}
.alert-receiver-modal-wrap-index .search-box-wrap {
  margin-top: 2rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box {
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 3rem;
  gap: 2.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .flier-box-wrap {
  height: 30rem;
  background-color: #014345;
  border-radius: 1.2rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .flier-box-wrap .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .text-amount- {
  font-size: 1.25rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .text-amount- span {
  color: unset !important;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .img-box {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .amount-show-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap {
  display: flex;
  flex-direction: column;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .label {
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  color: #020202;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box {
  padding: 1.5rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .name {
  font-size: 1.35rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .name-account-num-box .acc-num {
  font-size: 2rem;
  font-weight: 700;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border-width: 0.15rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box {
  width: 100%;
  height: 100%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .settle-ment-account-wrap .settle-ment-account-box .icon-wrap-box .icon-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .no-alert-to-view-box {
  display: grid;
  place-items: center;
  flex: 1;
}
.alert-receiver-modal-wrap-index .receiver-list-box .no-alert-to-view-box p {
  text-align: center;
  font-size: 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box::-webkit-scrollbar {
  display: none;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .form-label {
  margin-bottom: 0.6rem;
  font-size: 1.3rem;
  color: #020202;
  text-transform: capitalize;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box .item-select {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer !important;
}
.alert-receiver-modal-wrap-index .receiver-list-box .form-group .select-option-list-box .item-select .name {
  font-size: 1.25rem;
  cursor: pointer;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.6rem;
  border-width: 0.15rem;
  padding: 2rem 1.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box {
  background-color: #fff5f5;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box .img-box {
  width: 55%;
  height: 55%;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .delete-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box {
  display: flex;
  flex-direction: column;
  grid-gap: 0.4rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .name {
  font-weight: 600;
  font-size: 1.6rem;
  text-transform: capitalize;
}
.alert-receiver-modal-wrap-index .receiver-list-box .item-box .name-num-box .number {
  font-size: 1.25rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1.5rem;
  background-color: rgb(255, 246, 237);
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  border-color: rgb(234, 135, 45);
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.alert-receiver-modal-wrap-index .receiver-list-box .note-box-wrap .text {
  font-size: 1.4rem;
}

.edit-single-terminal-label-wrapper-index-box {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}
.edit-single-terminal-label-wrapper-index-box .title-text-box {
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
  border-width: 0.15rem;
  padding-bottom: 1.5rem;
}
.edit-single-terminal-label-wrapper-index-box .title-text-box .title {
  font-weight: 600;
  font-size: 2.3rem;
}
.edit-single-terminal-label-wrapper-index-box .title-text-box .text {
  color: rgb(103, 103, 103);
  font-size: 1.3rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 3rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box {
  height: 0;
  background-color: red;
  width: 100%;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box > *:last-child {
  border: unset;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .label-value-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .label-value-box .label {
  font-size: 1.3rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .label-value-box .value {
  font-size: 2rem;
  font-weight: 600;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .icon-wraps-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .icon-wraps-box .icon-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: relative;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .icon-wraps-box .icon-box .white-black-color {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .icon-wraps-box .icon-box .img-box {
  width: 50%;
  height: 50%;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box .label-value-copy-icon-box .icon-wraps-box .icon-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.edit-single-terminal-label-wrapper-index-box .form-box .view-info-mode-wrap-box-show {
  height: 16rem;
  overflow: visible;
}
.edit-single-terminal-label-wrapper-index-box .form-box .edit-info-mode-wrap-box {
  height: 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-bottom: -2rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .edit-info-mode-wrap-box-show {
  height: 12rem;
  overflow: visible;
}
.edit-single-terminal-label-wrapper-index-box .form-box .stream-number-input-box {
  width: 100%;
}
.edit-single-terminal-label-wrapper-index-box .form-box .stream-number-input-box .input .form-input {
  background-color: transparent;
  border: unset !important;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}
.edit-single-terminal-label-wrapper-index-box .form-box .stream-number-input-box .input .form-input::-moz-placeholder {
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: -0.08rem;
  color: rgba(0, 0, 0, 0.1019607843);
}
.edit-single-terminal-label-wrapper-index-box .form-box .stream-number-input-box .input .form-input::placeholder {
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: -0.08rem;
  color: rgba(0, 0, 0, 0.1019607843);
}
.edit-single-terminal-label-wrapper-index-box .form-box .down-label-generate-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: -2.5rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .down-label-generate-box .label-down {
  font-size: 1.35rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .list-box-to-set {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .list-box-to-set .label-check-box {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .list-box-to-set .label-check-box .label {
  color: #333333;
  font-size: 1.35rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .date-input-wrap-box-stream {
  width: 100%;
  height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.edit-single-terminal-label-wrapper-index-box .form-box .date-input-wrap-box-stream-show {
  height: 6rem;
  overflow: visible;
  margin-top: -1.5rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .two-factor-box-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.edit-single-terminal-label-wrapper-index-box .form-box .two-factor-box-wrap .text-line {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  width: 100%;
  display: none;
}
.edit-single-terminal-label-wrapper-index-box .form-box .two-factor-box-wrap .text-line .text {
  color: #676767;
  background-color: #f7f8f7;
  font-size: 1.1rem;
  border: 0.1rem solid #cccccc;
  padding: 0.5rem 1.1rem;
  border-radius: 10rem;
  font-weight: 600;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  text-transform: uppercase;
}
.edit-single-terminal-label-wrapper-index-box .form-box .two-factor-box-wrap .text-line span {
  height: 0.1rem;
  background-color: rgba(204, 204, 204, 0.5);
}
.edit-single-terminal-label-wrapper-index-box .flier-box-wrap {
  height: 50rem;
  background-color: #014345;
  border-radius: 1.2rem;
  margin-bottom: 7rem;
}
.edit-single-terminal-label-wrapper-index-box .flier-box-wrap .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.edit-single-terminal-label-wrapper-index-box .two-button-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 5rem;
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}
.edit-single-terminal-label-wrapper-index-box .two-button-wrap > * {
  width: 100%;
}
.edit-single-terminal-label-wrapper-index-box .two-button-wrap .btn-white {
  color: #020202 !important;
}/*# sourceMappingURL=AddAlertRecievers.css.map */